// @ts-nocheck
import { forwardRef } from "@chakra-ui/react";
import { useEffect, useRef, useState, Children, isValidElement, useLayoutEffect } from "react";
import type {
    FC,
    ReactElement,
    ReactNode,
    ReactPortal,
    Ref,
    ReactChild,
    ReactFragment,
} from "react";
import { H } from "../../content/heading/A11yHeading";
import { IconButton } from "../../forms/icon-button/IconButton";
import type { BoxProps } from "../../layout/box/Box";
import { Box } from "../../layout/box/Box";
import type { FlexProps } from "../../layout/flex/Flex";
import { Flex } from "../../layout/flex/Flex";
import { styles } from "./CarouselStyles";
import { useNavigationControls } from "./hooks/useNavigationControls";
import { useSliderNodeHandler } from "./hooks/useSliderNodeHandler";
import { useSlidesPerView } from "./hooks/useSlidesPerView";
import { ChevronLeftIcon, ChevronRightIcon } from "./Icons";
/* Swiper Carousel imports only for Hero Carousel*/
import { A11y, Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import type { SwiperProps } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperClass } from "swiper/types";
import { sizes } from "../../../design-tokens";
import { CustomProgressBar } from "./Progress";

export type CarouselProps = BoxProps & {
    extraNavigationButtons?: ReactNode;
    showNavigation?: boolean;
    titleAriaLabel?: string;
    slidesPerView?: number | number[];
    gap?: number;
    justifySlider?: string | string[];
    timer?: boolean;
    delay?: number;
    newActiveSlide?: number;
    navsx?: {
        rightButton?: object;
        leftButton?: object;
        flexContainer?: object;
    };
};
export type CarouselSliderProps = FlexProps;
export type CarouselItemProps = FlexProps & {
    itemWidth: number;
    ref?: Ref<HTMLDivElement>;
    index?: number;
    setActiveSlide?: (index: number) => void;
    setActiveTheme?: (theme: string) => void;
};
export type CarouselNavigationProps = FlexProps &
    SwiperProps & {
        arrayChildren: (ReactChild | ReactFragment | ReactPortal)[];
        sliderNode: HTMLDivElement;
        itemWidth: number;
        nextSlideTitle?: string;
        previousSlideTitle?: string;
        navsx?: {
            rightButton?: object;
            leftButton?: object;
            flexContainer?: object;
        };
    };
// We need to move the hook one level up, so the pagination and the navigation are linked.
export type CarouselHeroProps = FlexProps & {
    children: ReactElement | ReactElement[];
    extraNavigationButtons?: ReactNode;
    timer?: boolean;
    carouselIndicators?: boolean;
    iconSize?: object;
    containerStyle?: object;
    selectedImageIndex?: number;
    previousSlideTitle?: string;
    nextSlideTitle?: string;
    customNavigation?: ReactElement;
    navsx?: {
        rightButton?: object;
        leftButton?: object;
        flexContainer?: object;
    };
};
export type CarouselProductImageNavigationProps = Omit<CarouselHeroProps, "children"> & {
    onClick: () => void;
    activeTheme: string;
    navsx?: {
        rightButton?: object;
        leftButton?: object;
        flexContainer?: object;
    };
};

const DEFAULT_GAP = 8;
const DEFAULT_TITLE = "";
const DEFAULT_SLIDES_PER_VIEW = 1.5;
const DEFAULT_SHOW_NAVIGATION = false;
const DEFAULT_TITLE_ARIA_LABEL = "Carousel Title";
const DEFAULT_SCROLL_SNAP_ALIGN = "start";
const DEFAULT_EXTRA_NAVIGATION_BUTTONS = null;
const DEFAULT_JUSTIFY_SLIDER = "flex-start";
const DEFAULT_TIMER = false;
const DEFAULT_ICON_SIZE = {
    height: `${sizes.icon["4xs"]} !important`,
    width: `${sizes.icon["4xs"]} !important`,
};

export const Carousel: FC<CarouselProps> = ({
    h,
    sx,
    navsx,
    gap = DEFAULT_GAP,
    title = DEFAULT_TITLE,
    height,
    children,
    showNavigation = DEFAULT_SHOW_NAVIGATION,
    titleAriaLabel = DEFAULT_TITLE_ARIA_LABEL,
    slidesPerView: _slidesPerView = DEFAULT_SLIDES_PER_VIEW,
    scrollSnapAlign = DEFAULT_SCROLL_SNAP_ALIGN,
    extraNavigationButtons = DEFAULT_EXTRA_NAVIGATION_BUTTONS,
    justifySlider = DEFAULT_JUSTIFY_SLIDER,
    newActiveSlide,
    ...rest
}): JSX.Element => {
    const { slidesPerView } = useSlidesPerView(_slidesPerView);
    const { sliderRef, itemWidth, sliderNode } = useSliderNodeHandler<HTMLDivElement>(
        slidesPerView,
        gap
    );

    const arrayChildren = Children.toArray(children);
    const amountOfSlides = arrayChildren.length;
    const showNavigationControls = showNavigation && amountOfSlides > slidesPerView;
    const showHeader = title || showNavigationControls || extraNavigationButtons;

    const { scrollToSlide } = useNavigationControls(
        sliderNode,
        itemWidth,
        arrayChildren as ReactChild[]
    );
    const carouselNavigationProps = {
        itemWidth,
        sliderNode,
        arrayChildren: arrayChildren as ReactChild[],
    };

    const carouselSliderProps = {
        gap,
    };

    const carouselItemProps = {
        h,
        height,
        itemWidth,
        scrollSnapAlign,
    };

    useLayoutEffect(() => {
        if (itemWidth > 0 && newActiveSlide !== undefined) {
            // Need to wait until render is done to make an update of active element and do scroll
            setTimeout(() => {
                scrollToSlide(newActiveSlide);
            }, 100);
        }
    }, [newActiveSlide, scrollToSlide, itemWidth]);

    return (
        <Box as="nav" sx={{ ...styles.carousel, ...sx }} {...rest}>
            {showHeader && (
                <Flex
                    justifyContent={title ? "space-between" : "flex-end"}
                    alignItems="center"
                    px={[4, 4, 4, 6]}
                >
                    {title && (
                        <H
                            as={"h5"}
                            fontSize={["mobileH5", "tabletH5", "desktopH5"]}
                            /* In order to get the transition on the scrollbar, we need overwrite this value with important to avoid hide the headline. TBD by Designers. */
                            color="black !important"
                            aria-label={titleAriaLabel}
                            textTransform={"uppercase"}
                        >
                            {title}
                        </H>
                    )}
                    <Flex gap={2} color="black">
                        {extraNavigationButtons}
                        {showNavigationControls && (
                            <CarouselNavigation {...carouselNavigationProps} />
                        )}
                    </Flex>
                </Flex>
            )}
            <CarouselSlider
                ref={sliderRef}
                mt={showHeader ? 3 : "unset"}
                justifyContent={justifySlider}
                /* The spacing on the last slide on mobile should be the same as in the gap */
                paddingRight={[`${gap}px`, `${gap}px`, 0]}
                {...carouselSliderProps}
            >
                {Children.map(arrayChildren, (child) => (
                    <CarouselItem {...carouselItemProps}>{child}</CarouselItem>
                ))}
            </CarouselSlider>
        </Box>
    );
};

export const CarouselSlider = forwardRef<CarouselSliderProps, "div">(
    ({ sx, gap, children, ...rest }, ref): JSX.Element => {
        return (
            <Flex sx={{ ...styles.carouselSlider, ...sx }} gap={`${gap}px`} {...rest} ref={ref}>
                {children}
            </Flex>
        );
    }
);

export const CarouselItem: FC<CarouselItemProps> = ({
    children,
    itemWidth,
    ...rest
}): JSX.Element => {
    return (
        <Flex {...rest} minW={`${itemWidth}px`}>
            {children}
        </Flex>
    );
};

export const CarouselNavigation: FC<CarouselNavigationProps> = ({
    itemWidth,
    sliderNode,
    arrayChildren,
    nextSlideTitle = "",
    previousSlideTitle = "",
    ...rest
}): JSX.Element => {
    const { isNextDisabled, isPreviousDisabled, scrollToNextSlide, scrollToPrevSlide } =
        useNavigationControls(sliderNode, itemWidth, arrayChildren);
    return (
        <Flex display={["none", "flex"]} gap={2} sx={{ ...styles.carouselNavigation }} {...rest}>
            <IconButton
                onClick={scrollToPrevSlide}
                isRound
                variant={"tertiary"}
                aria-label={previousSlideTitle}
                w={10}
                h={10}
                disabled={isPreviousDisabled}
                data-testid="carousel-previous-button"
            >
                <ChevronLeftIcon />
            </IconButton>
            <IconButton
                onClick={scrollToNextSlide}
                isRound
                variant={"tertiary"}
                aria-label={nextSlideTitle}
                w={10}
                h={10}
                disabled={isNextDisabled}
                data-testid="carousel-next-button"
            >
                <ChevronRightIcon />
            </IconButton>
        </Flex>
    );
};

export const CarouselProductImageNavigation: FC<CarouselProductImageNavigationProps> = ({
    containerStyle,
    opacity,
    iconSize,
    previousSlideTitle = "",
    nextSlideTitle = "",
    activeTheme,
    onClick,
    navsx = { rightButton: {}, leftButton: {}, flexContainer: {} },
}) => {
    const { rightButton, leftButton, flexContainer } = navsx;
    return (
        <Box sx={styles.iconIndicatorContainer}>
            <Box sx={styles.iconIndicatorSubContainer}>
                <Box sx={styles.iconContainer}>
                    <Flex sx={{ ...containerStyle, ...flexContainer }}>
                        <IconButton
                            className="swiper-button-prev"
                            isRound
                            variant={"standard"}
                            colorScheme={activeTheme === "Light" ? "white" : "black"}
                            aria-label={previousSlideTitle}
                            icon={<ChevronLeftIcon />}
                            opacity={opacity}
                            sx={{ ...styles.iconButton, ...iconSize, ...leftButton }}
                            onClick={onClick}
                        />
                        <IconButton
                            className="swiper-button-next"
                            isRound
                            variant={"standard"}
                            colorScheme={activeTheme === "Light" ? "white" : "black"}
                            aria-label={nextSlideTitle}
                            icon={<ChevronRightIcon />}
                            opacity={opacity}
                            sx={{ ...styles.iconButton, ...iconSize, ...rightButton }}
                            onClick={onClick}
                        />
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};

export const CarouselProductImage: FC<CarouselHeroProps> = ({
    children,
    timer = DEFAULT_TIMER,
    sx,
    customNavigation,
    iconSize = DEFAULT_ICON_SIZE,
    opacity = 1,
    containerStyle,
    selectedImageIndex = 0,
    nextSlideTitle = "",
    previousSlideTitle = "",
    navsx,
}): JSX.Element => {
    const arrayChildren = Children.toArray(children).filter((child): child is ReactElement =>
        isValidElement(child)
    );
    const [activeTheme, setActiveTheme] = useState(
        arrayChildren[0]?.props?.modules?.fields?.theme || "light"
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const swiperRef = useRef<SwiperClass>(null);

    useEffect(() => {
        const wistiaVideo = document.querySelector("#video-player-container");
        const parentNode = wistiaVideo?.parentElement;
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes") {
                    const isReady = (mutation.target as HTMLElement).getAttribute(
                        "data-video-ready"
                    );
                    isReady === "true" && setIsLoaded(true);
                }
            });
        });
        if (parentNode) {
            observer.observe(parentNode, {
                attributes: true,
            });
        }

        if (!wistiaVideo) {
            setIsLoaded(false);
        }
        if (swiperRef.current) {
            swiperRef.current && timer && (isLoaded || !wistiaVideo)
                ? swiperRef.current.autoplay.start()
                : swiperRef.current.autoplay.stop();
        }

        return () => {
            if (parentNode) {
                observer.disconnect();
            }
        };
    }, [isLoaded]);

    const timerClass = timer ? "swiper-pagination-timer" : "swiper-pagination";
    const swiperOptions: SwiperProps = {
        watchSlidesProgress: true,
        resistance: true,
        resistanceRatio: 0,
        tag: "div",
        speed: 450,
        autoHeight: true,
        modules: [Navigation, Pagination, A11y, Autoplay, Keyboard],
        loop: timer ? true : false,
        spaceBetween: 24,
        initialSlide: selectedImageIndex ?? 0,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            disabledClass: "swiper-button-disabled",
        },
        // TODO: Handle accessibility properly for the Swiper
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
        },
        observer: true,
        observeParents: true,
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        pagination: {
            clickable: true,
            el: `.${timerClass}`,
            type: "bullets",
        },
        autoplay: timer
            ? { delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true }
            : false,
        style: { position: "relative", overflow: "clip" },
        slidesPerView: 1,
    };
    const swiperNavigationContainer = {
        ...styles.swiperNavigationContainer,
        ...containerStyle,
    };

    const [activeSlide, setActiveSlide] = useState<number>(selectedImageIndex ?? 0);
    /*ts before 5.5 throws error because expression  can't be used to index type
      this however is wrong, the styles[string] is not trying to look up an array index
      but returning an object by dynamic key.
    */
    const totalSlides = arrayChildren.length;
    const progressValue = ((activeSlide + 1) / totalSlides) * 100;
    return (
        <Box sx={{ ...styles.swiperContainer, ...sx }} data-video-ready={isLoaded}>
            <Swiper
                onSlideChange={(swiper: SwiperClass) => {
                    const activeIndex = swiper.activeIndex;
                    const realIndex = swiper.realIndex;
                    const activeChild = arrayChildren[activeIndex];
                    setActiveTheme(activeChild?.props?.module?.fields?.theme as string);
                    setActiveSlide(realIndex);
                }}
                onSwiper={(swiper: SwiperClass) => ((swiperRef.current as any) = swiper)}
                {...swiperOptions}
                scrollbar={{ draggable: true }}
            >
                {arrayChildren.map((child: ReactElement, index) => (
                    <SwiperSlide key={index}>{child}</SwiperSlide>
                ))}
            </Swiper>
            {customNavigation ?? (
                <CarouselProductImageNavigation
                    containerStyle={swiperNavigationContainer}
                    activeTheme={activeTheme}
                    iconSize={iconSize}
                    nextSlideTitle={nextSlideTitle}
                    onClick={() => swiperRef?.current?.autoplay.stop()}
                    opacity={opacity}
                    previousSlideTitle={previousSlideTitle}
                    navsx={navsx}
                />
            )}
            <CustomProgressBar value={progressValue} />
        </Box>
    );
};
